<template>
  <div class="home-header flex flex-m">
    <!-- {{ time }} -->
    <div class="flex flex-m home-header-content">
      <div class="home-header-avtor-circle flex flex-c flex-m">
        <el-image
          class="home-header-avtor-icon"
          :src="item.iconAvthor"
          fit="fit"
        ></el-image>
      </div>
      <div class="home-header-title">
        <div style="margin-bottom: 13px">
          {{ item.staffName }}
          <span class="home-header-title-hello">{{ $t("el.home.hello") }}</span>
        </div>
        <div class="home-header-title-school">
          {{ $t("el.home.schoolDivision") }}{{ item.schoolName }}
        </div>
      </div>
      <div v-if="$store.state.user.canAccess" class="flex home-header-btn">
        <div
          v-if="!$store.state.user.isHiddenSign"
          class="home-header-btn-item-1 flex flex-m"
          @click="toMyTimeTable"
        >
          <el-image
            class="home-header-btn-course-table"
            :src="iconMyCourseTable"
            fit="fit"
          ></el-image>
          {{ $t("el.home.mySchedule") }}
        </div>
        <!-- <div class="home-header-btn-item-2 flex flex-m" @click="toMyCourse">
          <el-image
            class="home-header-btn-course"
            :src="iconMyCourse"
            fit="fit"
          ></el-image>
          {{ $t("el.home.myCourses") }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconMyCourseTable: require("../../../assets/images/icon/icon-my-course-table.png"),
      iconMyCourse: require("../../../assets/images/icon/icon-my-course.png"),
      time: 0,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    // setInterval(() => {
    //   this.time = this.time + 1;
    // }, 1000);
  },
  methods: {
    // 跳转我的课表
    toMyTimeTable() {
      this.$router.push({
        name: "myTimeTable",
      });
    },
    // 跳转我的课程
    toMyCourse() {
      this.$router.push({
        name: "myCourses",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home-header {
  // width: 1200px;
  height: 0;
  padding-bottom: 10.83%;
  background: url("../../../assets/images/icon/bg-header-top.png") no-repeat;
  background-size: 100% 100%;
  &-content {
    width: 100%;
    margin-top: 10.83%;
  }
  &-avtor-circle {
    position: relative;
    width: 7.16%;
    height: 0;
    padding-bottom: 7.16%;
    border: 2px solid #f0eefd;
    border-radius: 50%;
    margin-left: 21px;
  }
  &-avtor-icon {
    position: absolute;
    top: 5.16%;
    width: 90.69%;
    height: auto;
    // padding-bottom: 90.60%;
    border-radius: 50%;
  }
  &-title {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #fec87b;
    margin-left: 19px;
    &-hello {
      font-size: 18px;

      font-weight: 400;
      color: #ffffff;
    }
    &-school {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  &-btn {
    margin-right: 25px;
    margin-left: auto;
    &-item-1 {
      padding-left: 15px;
      padding-right: 15px;
      height: 36px;
      background: #ffffff;
      box-shadow: 0px 3px 4px 0px rgba(67, 76, 174, 0.26);
      border-radius: 18px;

      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #cf6779;
      margin-right: 20px;
      cursor: pointer;
    }
    &-item-2 {
      padding-left: 20px;
      padding-right: 20px;
      height: 36px;
      background: #ffffff;
      box-shadow: 0px 3px 4px 0px rgba(67, 76, 174, 0.26);
      border-radius: 18px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #606fdc;
      cursor: pointer;
    }
    &-course-table {
      width: 18px;
      height: 21px;
      margin-right: 8px;
    }
    &-course {
      width: 23px;
      height: 19px;
      margin-right: 7px;
    }
  }
}
</style>
