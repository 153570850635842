var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"content"},[_c('home-header',{attrs:{"item":{
        staffName: _vm.staffName,
        schoolName: _vm.user.schoolName,
        iconAvthor:
          _vm.gender === 2
            ? require('@/assets/images/subjectType/pic_face_woman@2x.png')
            : require('@/assets/images/subjectType/pic_face_man@2x.png'),
      }}}),_c('my-courses-list',{attrs:{"guideVisible":_vm.guideVisible},on:{"changeGuideDialog":(isShow) => {
          _vm.guideVisible = isShow;
        }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }