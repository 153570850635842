<template>
  <!-- 总课表/导入课表管理 -->
  <div class="total-course-list">
    <router-menu-title :routerTitleList="routerTitleList"></router-menu-title>
    <div class="week-show-content">
      <week-start-end-components
        @changeWeekDateRange="changeWeekDateRange"
      ></week-start-end-components>
      <div v-loading="loadingDetails" class="total-course-list-content">
        <table>
          <tr class="table-header">
            <th v-for="(item, index) in weekData" :key="index">
              {{ $t(numberTransformChinese(index)) }} {{ item }}
            </th>
          </tr>
        </table>

        <div v-show="maxLength !== 0" class="total-course-list-content-data">
          <div class="day-wrap" v-for="(num, nidex) in 7" :key="nidex">
            <div
              :class="{ 'border-left': nidex === 0 }"
              v-for="(item, index) in listData[`w${num}`]"
              :key="index"
              @click="toOpenDialog(item, nidex)"
            >
              <div class="day-content" v-if="item.coursePeriod">
                <el-popover
                  placement="top-start"
                  popper-class="hover-plan-course-details"
                  offset="50"
                  width="220"
                  trigger="manual"
                >
                  <div class="hover-details">
                    <p class="course-name">
                      {{ $t("el.myTimeTable.Courses") }}
                      {{ item.courseName }}
                    </p>
                    <p class="course-name">
                      {{ $t("el.myTimeTable.Class") }}
                      {{ item.className }}
                    </p>
                    <p class="course-name">
                      {{ $t("el.myTimeTable.Time") }}
                      {{ weekData[nidex] }}
                      {{ item.coursePeriod }}
                    </p>
                    <p class="course-name">
                      {{ $t("el.myTimeTable.Teacher") }}
                      {{ item.staffName }}
                    </p>
                    <p class="course-name">
                      {{ $t("el.myTimeTable.Classroom") }}
                      {{ item.classroomName }}
                    </p>
                  </div>
                  <div slot="reference">
                    <p class="day-content-period one-line-ellipsis">
                      {{ item.coursePeriod }}
                    </p>
                    <p class="day-content-name">{{ item.courseName }}</p>
                    <p class="day-content-details">
                      <span class="class-name">{{ item.className }} </span>
                      <span class="staff-name"> / {{ item.staffName }}</span>
                    </p>
                  </div>
                </el-popover>
              </div>
              <div class="day-content" v-else>
                <p class="no-detail" v-if="index === 0">
                  {{ $t("el.planCourse.noArrangement") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="maxLength === 0" class="empty-content-data">
          <div style="padding-top: 111px; line-height: 0">
            <img
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 270px"
            />
          </div>
          <div
            style="
              color: rgba(100, 108, 149, 0.25);
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1px;
              padding-bottom: 120px;
            "
          >
            {{ $t("el.common.noData") }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="上课详情"
      :visible.sync="dialogVisible"
      width="650px"
    >
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="basic">
            <div class="course-details">
              <p class="course-name">
                课程名称：
                {{ courseDetails.courseName }}
              </p>
              <p class="course-name">
                上课老师：
                {{ courseDetails.staffName }}
              </p>
              <p class="course-name">
                上课时间：
                {{ weekData[courseDetails.wIndex] }}
                {{ courseDetails.coursePeriod }}
              </p>
              <p class="course-name">
                上课教室：
                {{ courseDetails.classroomName }}
              </p>
              <p class="course-name">
                上课班级：
                {{ courseDetails.className }}
              </p>
              <p class="course-name">
                上课人数：
                {{ courseDetails.studentNum }}
              </p>
              <p class="course-name">
                课程学年：
                {{ courseDetails.schoolYear }}
              </p>
              <p class="course-name">
                课程学期：
                {{ courseDetails.term }}
              </p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="学生信息" name="student">
            <el-image :src="computedImg"></el-image>
            <p class="student-tips">{{ computedText }}</p>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { windowOpen } from "@/utils";
import { getdiffdate, getFormatterTime } from "@/utils/index";
import routerMenuTitle from "@/components/biz-components/router-menu-title/index.vue";
import weekStartEndComponents from "@/components/use-components/week-startEnd-components";
import { getCourseClassDeleteStatus } from "@/api/manage/course-list.js";

export default {
  components: { weekStartEndComponents, routerMenuTitle },
  // 定义数据
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      queryParams: {},
      startDate: "",
      endDate: "",
      defaultSchoolCode: "",
      weekData: [],
      listData: {
        w1: [],
        w2: [],
        w3: [],
        w4: [],
        w5: [],
        w6: [],
        w7: [],
      },
      maxLength: null,
      loadingDetails: false,
      isreset: false,
      showPicker: true,
      dialogVisible: false,
      courseDetails: {},
      activeName: "basic",
      courseClassEmptyImg: require("@/assets/images/course_class_empty.png"),
      courseClassStudentImg: require("@/assets/images/course_class_student.png"),
      classIsDelete: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.classroomId === undefined) {
      // 如果没有教室id,则自动重定向到教室列表
      next((vm) => {
        vm.$router.replace({ path: "/manage/base/classroomSetting" });
      });
    } else {
      next();
    }
  },
  computed: {
    routerTitleList() {
      return [
        {
          name: "教室管理",
        },
        {
          name: "上课管理",
        },
      ];
    },
    computedImg() {
      if (~~this.classIsDelete === 0) {
        return this.courseClassStudentImg;
      } else {
        return this.courseClassEmptyImg;
      }
    },
    computedText() {
      if (~~this.classIsDelete === 0) {
        return "请前往学生信息管理页面查看";
      } else {
        return "当前课程班已被解散，请联系管理员处理";
      }
    },
  },
  async created() {
    // await this.getGeneralWeekData();
  },
  methods: {
    // 打开课程详情弹出框
    toOpenDialog(item, nidex) {
      this.courseDetails = { ...item, wIndex: nidex };
      this.dialogVisible = true;
    },
    async handleClick(tab, event) {
      if (tab.index === "1") {
        try {
          const { data } = await getCourseClassDeleteStatus(
            this.courseDetails.classId
          );
          this.classIsDelete = data;
          if (~~this.classIsDelete === 0) {
            const detailInfo = this.courseDetails;
            let routeData = this.$router.resolve({
              path: "/planCourseManage/courseClassManage",
              query: {
                courseId: detailInfo.courseId,
                courseName: detailInfo.courseName,
                tenantInfoId: detailInfo.courseTenantInfoId,
                tenantType: detailInfo.courseTenantType,
                tenantId: detailInfo.courseTenantId,
                originTenantId: detailInfo.originTenantId,
                classId: detailInfo.classId,
              },
            });
            windowOpen(routeData.href, "_blank");
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    // 更改选择周
    changeWeekDateRange(data, isChangeLanguage) {
      this.startDate = data.startDate;
      this.endDate = data.endDate;

      this.weekData = getdiffdate(this.startDate, this.endDate, "/");
      this.loadingDetails = true;
      this.getGeneralWeekData();
    },
    // 列表数据格式化
    formateList(data, valueData, labelData) {
      data.forEach((item) => {
        this.$set(item, "value", item[valueData]);
        this.$set(item, "label", item[labelData]);
      });
      return data;
    },
    // 日期数字转中文
    numberTransformChinese(index) {
      return [
        "el.common.Monday",
        "el.common.Tuesday",
        "el.common.Wednesday",
        "el.common.Thursday",
        "el.common.Friday",
        "el.common.Saturday",
        "el.common.Sunday",
      ][index];
    },
    // table周数据补全
    listDataFormate() {
      // 先取数组的最大长度值
      let maxLength = 0;
      for (let key in this.listData) {
        if (this.listData[key].length > 0) {
          if (this.listData[key].length > maxLength) {
            maxLength = this.listData[key].length;
          }
        }
      }
      console.log(maxLength);
      this.maxLength = maxLength;
      // 补全结构
      for (let key in this.listData) {
        for (let o = 0; o < maxLength; o++) {
          if (this.listData[key].length < maxLength) {
            let addLength = maxLength - this.listData[key].length;
            for (let ele = 0; ele < addLength; ele++) {
              this.listData[key].push([]);
            }
          }
        }
      }
      console.log(this.listData);
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      return params;
    },
    // 获取课程表数据
    async getGeneralWeekData() {
      this.listData = this.$options.data().listData;
      this.maxLength = null;
      const params = {
        startDate: this.startDate.replaceAll("/", "-"),
        endDate: this.endDate.replaceAll("/", "-"),
        classroomId: this.$route.query.classroomId,
      };
      console.log(params);
      delete params.dateRange;
      await this.$store
        .dispatch("getGeneralImportCourse", params)
        .then((res) => {
          let data = res.data;
          if (Array.isArray(data)) {
            if (data.length > 0) {
              data.forEach((item) => {
                for (let key in this.listData) {
                  console.log(key);
                  if (key === item.dayOfWeek) {
                    this.listData[key] = item.periodContents;
                  }
                }
              });
              console.log(this.listData);
              this.loadingDetails = false;
              this.listDataFormate();
            } else {
              this.loadingDetails = false;
              this.maxLength = 0;
            }
          } else {
            this.loadingDetails = false;
            this.maxLength = 0;
          }
        })
        .catch(() => {
          this.loadingDetails = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.total-course-list {
  background: #ffffff;
  padding: 30px 50px;
  border-radius: 10px;
  /deep/ .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      margin-bottom: 22px;
      display: flex;
      margin-right: 20px;
    }
  }
  /deep/ .el-scrollbar {
    height: 340px;
  }
  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default,
  .el-button--default:hover {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
  &-content {
    margin-top: 20px;
    table {
      border-collapse: collapse;
      width: 100%;
      .table-header {
        border: 1px solid #ebebeb;
        width: 100%;
      }
      th {
        background: #f5f6f7;
        font-size: 14px;
        color: #323232;
        font-weight: normal;
        width: calc(100% / 7);
        height: 50px;
        line-height: 50px;
        border-right: 1px solid #ebebeb;
        text-align: center;
        padding-left: 12px;
      }
    }
    &-data {
      display: flex;
      width: 100%;
      p {
        margin: 0;
      }
      .day-wrap {
        width: calc(100% / 7);
        background: #fff;
      }
      .day-content {
        height: 85px;
        border: 1px solid #ebebeb;
        border-top: none;
        border-left: none;
        padding: 20px 12px;
        font-size: 14px;
        color: #323232;
        &-period {
          color: #000;
          font-weight: bold;
          margin-bottom: 8px;
        }
        &-name {
          white-space: pre-wrap;
          width: 100%;
          margin-bottom: 8px;
        }
        &-details {
          margin-bottom: 8px;
        }
        .one-line-ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          height: 15px;
        }
        .no-detail {
          color: rgba(#000, 0.25);
        }
      }
      .border-left {
        border-left: 1px solid #ebebeb;
      }
    }
  }
  .week-show-content {
    margin-top: 25px;
    min-height: 420px;
  }
  .list-params {
    display: flex;
    flex-wrap: wrap;
    &-wrap {
      margin-bottom: 22px;
      display: inline-block;
      margin-right: 10px;
      vertical-align: top;
    }
    .long {
      .el-select {
        width: 310px;
      }
    }
    label {
      text-align: right;
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0 0;
    }
  }
  .empty-content-data {
    text-align: center;
    border: 1px solid #ebebeb;
    border-top: none;
  }
  .el-loading-spinner {
    padding: 20px 0;
  }
}
/deep/ .el-dialog {
  height: 400px;
  .el-dialog__body {
    padding: 10px 20px;
    .el-tabs {
      .el-tabs__active-bar {
        bottom: 2px !important;
      }
      .el-tabs__item.is-active {
        background: transparent !important;
      }
      .el-tabs__item {
        font-size: 16px;
      }
    }
    .el-image__inner {
      width: 280px;
      margin: 35px 0 20px;
    }
    .student-tips {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.course-details {
  font-size: 15px;
  text-align: start;
  .course-name {
    padding-bottom: 15px;
  }
}
</style>
<style lang="less">
.hover-plan-course-details {
  p {
    color: #6049ff;
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0;
  }
}
</style>
