<template>
  <div class="home">
    <div class="content">
      <home-header
        :item="{
          staffName,
          schoolName: user.schoolName,
          iconAvthor:
            gender === 2
              ? require('@/assets/images/subjectType/pic_face_woman@2x.png')
              : require('@/assets/images/subjectType/pic_face_man@2x.png'),
        }"
      ></home-header>
      <!-- <categoty-content></categoty-content> -->
      <my-courses-list
        :guideVisible="guideVisible"
        @changeGuideDialog="
          (isShow) => {
            guideVisible = isShow;
          }
        "
      ></my-courses-list>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import HomeHeader from "./components/HomeHeader.vue";
import MyCoursesList from "@/views/myCourseList/index.vue";
import { getQueryUserAuthInfo } from "@/api/home";
// import CategotyContent from "@/views/home/components/CategotyContent";
var that;

export default {
  name: "Home",
  components: {
    HomeHeader,
    // CategotyContent,
    MyCoursesList,
  },
  props: {},
  data() {
    return {
      allLoading: false,
      guideVisible: false,
      categotyList: [],
      categoryIdList: [],
      hasCategory: -2,
      pagingParamsAuth: {
        limit: 5,
        offset: 0,
        page: 1,
        pages: 1,
      },
      authList: [],
      loading: false,
      pageInfoAuth: {
        total: 0,
      },
    };
  },
  // 侦听器
  watch: {
    /**
     * 分页参数改变重新获取数据
     */
    pagingParamsAuth() {
      this.getAuthData();
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters([
      "staffName",
      "gender",
      "regulator",
      "canAccess",
      "isNeedGuide",
    ]),
  },
  filters: {},
  beforeCreate() {
    that = this;
  },
  beforeRouteEnter(to, from, next) {
    // ...
    console.log("from.name", from.name, "to", to);
    next((vm) => {
      // 用来判断是否缓存
      if (from.name !== "CourseDetails" && from.name !== "hdCourseDetails") {
        to.meta.isBack = false;
      } else {
        to.meta.isBack = true;
      }
    });
  },
  async created() {
    // 引导页
    if (this.isNeedGuide()) {
      this.triggerGuide();
      this.guideVisible = true; // false：引导取消，true：有引导
    }
  },
  mounted() {},
  methods: {
    ...mapActions(["triggerGuide"]),
    // 获取权限联系方式
    getAuthData() {
      return getQueryUserAuthInfo(this.pagingParamsAuth)
        .then(({ data }) => {
          if (Array.isArray(data.records)) this.authList = data.records;
          this.pageInfoAuth.total = data.total || 0;
          this.pagingParamsAuth.pages = data.pages || 0;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  background-color: #f6f8fc;
}
/deep/ .el-dialog__headerbtn:focus .el-dialog__close,
/deep/ .el-dialog__headerbtn:hover .el-dialog__close {
  color: @themeBlue;
}
.home {
  .content {
    padding-top: 32px;

    .empty-view {
      background: #ffffff;
      padding-bottom: 80px;
      border-radius: 10px;
    }

    .no-auth {
      width: 700px;
      margin: 0 auto;
      .head {
        font-size: 18px;
        color: rgba(100, 108, 149, 1);
        line-height: 24px;
        margin-top: 3px;
        margin-bottom: 25px;
        text-align: center;
      }
      .auth-data {
        margin-bottom: 38px;
      }
      /deep/ .el-tooltip__popper {
        position: absolute;
        border-radius: 4px;
        padding: 10px;
        z-index: 2000;
        font-size: 12px;
        line-height: 1.2;
        min-width: 10px;
        word-wrap: break-word;
        max-width: 500px;
        box-sizing: border-box;
      }
    }

    .categoty-content {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.ml20 {
  margin-left: 20px;
}
.mb40 {
  margin-bottom: 40px;
}
</style>
<style lang="less">
.el-tooltip__popper {
  position: absolute;
  border-radius: 4px;
  padding: 10px;
  z-index: 2000;
  font-size: 12px;
  line-height: 1.2;
  min-width: 10px;
  word-wrap: break-word;
  box-sizing: border-box;
  max-width: 500px;
}
</style>
