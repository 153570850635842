<template>
  <div class="my-time-table">
    <!-- <InstallTip/> -->
    <!-- <span @click="chooseclass">选择授课班级/课程</span> -->
    <!-- <span @click="openNoAuth">切换无权限</span> -->
    <div class="content">
      <div class="header">
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon goback-btn"
          @click="$router.go(-1)"
        >
          <i class="icon-navtop-back"></i>{{ $t("el.common.back") }}</el-button
        >
      </div>
      <div class="cantainer">
        <weekStartEnd @changeWeekDateRange="changeWeekDateRange"></weekStartEnd>

        <schoolTimetabel
          v-loading="allLoading"
          :tableList="tableList"
          :isChangeHoverStyle="false"
          :allowCourseTitleClick="false"
          :changeBg="true"
          class="timetable"
        >
          <template slot="timePeriodsHeader">
            <div class="time-periods-header">
              {{ $t("el.myTimeTable.timeDate") }}
            </div>
          </template>
        </schoolTimetabel>
      </div>
    </div>
  </div>
</template>

<script>
import weekStartEnd from "../../components/use-components/weekStartEnd.vue";
import schoolTimetabel from "../../components/biz-components/school-timetable/index.vue";
import { getdiffdate } from "../../utils/index";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      tableList: [],
      result: {},
      colorList: [
        {
          headBg: "#97c69b",
          contentBg: "#aadcaf",
        },
        {
          headBg: "#72c5be",
          contentBg: "#7ce0d8",
        },

        {
          headBg: "#edbf79",
          contentBg: "#f4cd92",
        },
        {
          headBg: "#9a96cc",
          contentBg: "#b2aee6",
        },
        {
          headBg: "#bc93bc",
          contentBg: "#d3a5d2",
        },
        {
          headBg: "#8c84b8",
          contentBg: "#9f96d0",
        },
        {
          headBg: "#e69e7c",
          contentBg: "#edad8f",
        },
        {
          headBg: "#fa8d78",
          contentBg: "#fa8d78",
        },
      ],
      startDate: "",
      endDate: "",
      allLoading: false,
      courseIdList: [],
    };
  },
  components: {
    weekStartEnd,
    schoolTimetabel,
  },

  computed: {},

  methods: {
    ...mapActions(["getMyCourseTable"]),
    // 周次改变
    changeWeekDateRange(e, isChangeLanguage) {
      // console.log("e", e.startDate.replace(/\//g, "-"));
      this.startDate = e.startDate;
      this.endDate = e.endDate;
      if (isChangeLanguage) {
        // console.log("isChangeLanguage",isChangeLanguage);
        this.convertTableData(this.result);
        // this.getTableData(
        //   e.startDate.replace(/\//g, "-"),
        //   e.endDate.replace(/\//g, "-")
        // );
      } else {
        this.getTableData(
          e.startDate.replace(/\//g, "-"),
          e.endDate.replace(/\//g, "-")
        );
      }
    },

    toHome() {
      this.$router.replace({
        name: "home",
      });
    },

    // 获取我的课表
    getTableData(startDate, endDate) {
      this.allLoading = true;
      let params = {
        startDate,
        endDate,
        // courseTableType: "1",
        // schoolCode:"122101104",
      };

      return this.getMyCourseTable(this.handparams(params))
        .then((result) => {
          // console.log("result", result);
          this.result = result;
          this.allLoading = false;
          this.convertTableData(result);
        })
        .catch(() => {
          this.allLoading = false;
        });
    },

    // 转换课表数据
    convertTableData(result) {
      let columns = [];
      let tableList = [];
      this.courseIdList = [];
      let a = {
        headerSlotName: "timePeriodsHeader",
        type: "common",
        prop: "coursePeriod",
        label: "",
        width: 90,
        fixed: "left",
        align: "center",
        "header-align": "center",
      };
      columns.push(a);
      let dayOfWeekCodes = [];
      //   let timePeriods = [];
      let weekPeriod = getdiffdate(this.startDate, this.endDate, "/");
      // console.log("weekPeriod", weekPeriod);
      // 获取横坐标表头数据
      weekPeriod.forEach((element, index) => {
        let b = {
          prop: "w" + (index + 1),
          width: 150,
          week: this.converToWeek(index),
          time: element,
        };
        dayOfWeekCodes.push("w" + (index + 1));
        columns.push(b);
      });

      let table = {
        // 组件每一条的数据，实际的数据
        columns,
        showTableTitle: false,
        tableData: [],
      };
      if (
        result.data &&
        result.data instanceof Array &&
        result.data.length > 0
      ) {
        let maxContent = 0;
        this.emptyData = false;
        result.data.forEach((e, i) => {
          dayOfWeekCodes.forEach((obj) => {
            e.contents.forEach((ele) => {
              if (ele.dayOfWeek === obj) {
                // console.log("ele.periodContents", ele.periodContents);
                maxContent =
                  maxContent > ele.periodContents.length
                    ? maxContent
                    : ele.periodContents.length;
              }
            });
          });
        });

        result.data.forEach((e, i) => {
          dayOfWeekCodes.forEach((obj) => {
            let a = [];
            let hasObj = false; // 判断数据中是否有obj 的值，有，赋值，无，置空 横坐标
            e.contents.forEach((ele) => {
              if (ele.dayOfWeek === obj) {
                hasObj = true;
                a = ele.periodContents; // periodContents 一周中当天的数据
                a.forEach((o) => {
                  let label = o.courseId + o.classId + o.staffCode;
                  if (this.courseIdList.indexOf(label) > -1) {
                    o.headBg =
                      this.colorList[
                        this.courseIdList.indexOf(label) % this.colorList.length
                      ].headBg;
                    o.contentBg =
                      this.colorList[
                        this.courseIdList.indexOf(label) % this.colorList.length
                      ].contentBg;
                  } else {
                    let n = this.courseIdList.length % this.colorList.length;

                    o.headBg = this.colorList[n].headBg;
                    o.contentBg = this.colorList[n].contentBg;
                    this.courseIdList.push(label);
                  }
                });
                const contentLength = ele.periodContents.length;
                // 课程数据补齐到最大长度
                if (maxContent > contentLength) {
                  for (let x = 0; x < maxContent - contentLength; x++) {
                    a.push({});
                  }
                }
              }
            });
            // console.log("a", a);
            if (hasObj) {
              e[obj] = a;
            } else {
              e[obj] = [];
              // 课程数据补齐到最大长度
              for (let x = 0; x < maxContent; x++) {
                e[obj].push({});
              }
            }
          });
        });

        table.tableData = result.data;
        tableList.push(table);
        this.tableList = tableList;
        // console.log("this.tableList",this.tableList);
      } else {
        this.emptyData = true;

        // table.tableData = [];
        // this.tableList = [table]
        this.tableList = []; // 组件数据只有一条时，这个没数据，就是整体没数据
      }
    },
    converToWeek(index) {
      let week = "";

      switch (index) {
        case 0:
          week = this.$t("el.common.Monday");
          break;
        case 1:
          week = this.$t("el.common.Tuesday");
          break;
        case 2:
          week = this.$t("el.common.Wednesday");
          break;
        case 3:
          week = this.$t("el.common.Thursday");
          break;
        case 4:
          week = this.$t("el.common.Friday");
          break;
        case 5:
          week = this.$t("el.common.Saturday");
          break;
        case 6:
          week = this.$t("el.common.Sunday");
          break;
        default:
          break;
      }
      return week;
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
.my-time-table {
  .content {
    padding-top: 32px;
    .header {
      display: flex;
      // justify-content: space-between;
      align-items: flex-end;
      // margin-bottom: 31px;
      .goback-btn {
        border-radius: 20px;
      }
      .title {
        display: flex;
        // align-items: center;
        // margin-bottom: 15px;
        // font-size: 28px;
        // font-weight: 800;
        // color: rgba(40, 40, 40, 1);
        // line-height: 40px;
        // letter-spacing: 1px;
        // cursor: pointer;

        font-size: 15px;
        line-height: 20px;
        padding: 7px 24px;
        border-radius: 17px;
        color: #282828;
        cursor: pointer;
        &-chose {
          font-size: 15px;
          line-height: 20px;
          padding: 7px 24px;
          border-radius: 17px;
          cursor: pointer;
          background-color: #6049ff;
          color: #ffffff;
          -webkit-box-shadow: 0px 2px 8px 0px rgb(111 138 163 / 20%);
          box-shadow: 0px 2px 8px 0px rgb(111 138 163 / 20%);
        }
        p {
          margin: 0;
        }
        // .text {
        //   font-size: 12px;
        //   color: rgba(111, 138, 163, 1);
        //   line-height: 16px;
        //   margin-left: 10px;
        //   display: inline-block;
        // }
        .course-header-img {
          width: 62px;
          margin-right: 10px;
        }
      }
    }
    .cantainer {
      width: 100%;
      min-height: 651px;
      background: #ffffff;
      border-radius: 5px;
      margin-top: 20px;
      padding: 32px 33px;
      box-sizing: border-box;
      .timetable {
        margin-top: 26px;
      }
      /deep/ .el-scrollbar {
        height: 340px;
      }
    }
    .time-periods-header {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #a9aec2;
      line-height: 21px;
    }
  }
  // 图标
  .icon-navtop-back {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    color: #fff;
    .gen-icon(white_navtop_back);
  }
}
</style>
